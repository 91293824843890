import logo from "./logo.svg";
import "./App.css";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import ContactUsPage from "./pages/contact_us";
import AboutPage from "./pages/about";
import ServicesPage from "./pages/services";
import FAQPage from "./pages/faq";
import RegisterPage from "./pages/register";
import LoginPage from "./pages/login";
import ForgotPage from "./pages/forgot-pass";
import HomePage from "./pages/home";
import AccountValidate from "./pages/account-validate";
import ResetPassPage from "./pages/reset-pass";
import PropertyPage from "./pages/property";
import AuthLayout from "./components/AuthLayout";
import PropertyDetailsPage from "./pages/propertyDetails";
import ProfilePage from "./pages/profile";
import PurchasedPage from "./pages/purchased";
import HowItWorksPage from "./pages/howItWorks";
import TermsAndConditionsPage from "./pages/termsAndconditions";
import NomineePage from "./pages/nominee"

const IsAuthenticated = ({ children }) => {
  // const user = localStorage.getItem("token");
  // if (!user) {
  //   return <Navigate to={"/login"} />;
  // }
  return children;
};

const IsNotAuthenticated = ({ children }) => {
  // const user = localStorage.getItem("token");
  // if (user) {
  //   return <Navigate to={"/home"} />;
  // }
  return children;
};
function App() {
  return (
    <Router>
      <Routes>
        <Route
          path="/register"
          element={
            <IsNotAuthenticated>
              <RegisterPage />
            </IsNotAuthenticated>
          }
        />
        <Route
          path="/reset-pass"
          element={
            <IsNotAuthenticated>
              <ResetPassPage />
            </IsNotAuthenticated>
          }
        />
        <Route
          path="/account-validate"
          element={
            <IsNotAuthenticated>
              <AccountValidate />
            </IsNotAuthenticated>
          }
        />
        <Route
          path="/forgot-password"
          element={
            <IsNotAuthenticated>
              <ForgotPage />
            </IsNotAuthenticated>
          }
        />
        <Route
          path="/login"
          element={
            <IsNotAuthenticated>
              <LoginPage />
            </IsNotAuthenticated>
          }
        />
        <Route
          path="/contact-us"
          element={
            <IsAuthenticated>
              <AuthLayout pageName={"Contact Us"}>
                <ContactUsPage />
              </AuthLayout>
            </IsAuthenticated>
          }
        />{" "}
        <Route
          path="/properties"
          element={
            <IsAuthenticated>
              <AuthLayout pageName={"Properties"}>
                <PropertyPage />
              </AuthLayout>
            </IsAuthenticated>
          }
        />
        
        <Route
          path="/how-it-works"
          element={
            <IsAuthenticated>
              <AuthLayout pageName={"HowItWorks"}>
                <HowItWorksPage />
              </AuthLayout>
            </IsAuthenticated>
          }
        />
        <Route
          path="/terms-and-conditions"
          element={
            <IsAuthenticated>
              <AuthLayout pageName={"TermsAndConditions"}>
                <TermsAndConditionsPage />
              </AuthLayout>
            </IsAuthenticated>
          }
        />
        <Route
          path="/home"
          element={
            <IsAuthenticated>
              <AuthLayout pageName={"Home"}>
                <HomePage />
              </AuthLayout>
            </IsAuthenticated>
          }
        />
        <Route
          path="/Profile"
          element={
            <IsAuthenticated>
              <AuthLayout pageName={"Profile"}>
                <ProfilePage />
              </AuthLayout>
            </IsAuthenticated>
          }
        />
        <Route
          path="/nominees-list"
          element={
            <IsAuthenticated>
              <AuthLayout pageName={"Nominee List"}>
                <NomineePage />
              </AuthLayout>
            </IsAuthenticated>
          }
        />
        <Route
          path="/purchased-properties"
          element={
            <IsAuthenticated>
              <AuthLayout pageName={"Property Details"}>
                <PurchasedPage />
              </AuthLayout>
            </IsAuthenticated>
          }
        />
        <Route
          path="/property-details"
          element={
            <IsAuthenticated>
              <AuthLayout pageName={"Property Details"}>
                <PropertyDetailsPage />
              </AuthLayout>
            </IsAuthenticated>
          }
        />
        <Route
          path="/"
          element={
            <IsAuthenticated>
              <AuthLayout pageName={"Home"}>
                <HomePage />{" "}
              </AuthLayout>
            </IsAuthenticated>
          }
        />
        <Route
          path="/about"
          element={
            <IsAuthenticated>
              <AuthLayout pageName={"About"}>
                <AboutPage />{" "}
              </AuthLayout>
            </IsAuthenticated>
          }
        />
        <Route
          path="/services"
          element={
            <IsAuthenticated>
              <AuthLayout pageName={"Services"}>
                <ServicesPage />{" "}
              </AuthLayout>
            </IsAuthenticated>
          }
        />
        <Route
          path="/faq"
          element={
            <IsAuthenticated>
              <AuthLayout pageName={"FAQ"}>
                <FAQPage />{" "}
              </AuthLayout>
            </IsAuthenticated>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
