import axios from "./axiosInstance";

export const signinApi = async (data) => {
  try {
    const result = await axios.post(`/user/login`, data);
    if (result) {
      return result;
    }
  } catch (err) {
    return { data: err?.response?.data };
  }
};

export const logoutApi = async () => {
  try {
    const AUTH_TOKEN = localStorage.getItem("token");
    axios.defaults.headers.common["token"] = AUTH_TOKEN;
    const result = await axios.post(`/user/logout`, {});
    if (result) {
      return result;
    }
  } catch (err) {
    return { data: err?.response?.data };
  }
};

export const signupApi = async (data) => {
  try {
    const result = await axios.post(`/user/signup`, data);
    if (result) {
      return result;
    }
  } catch (err) {
    return { data: err?.response?.data };
  }
};

export const forgotPasswordApi = async (data) => {
  try {
    const result = await axios.post(`/user/forgot-password`, data);
    if (result) {
      return result;
    }
  } catch (err) {
    return { data: err?.response?.data };
  }
};

export const changePasswordApi = async (data) => {
  try {
    const result = await axios.patch(`/user/change-password`, data);
    if (result) {
      return result;
    }
  } catch (err) {
    return { data: err?.response?.data };
  }
};

export const getProfileApi = async () => {
  try {
    const AUTH_TOKEN = localStorage.getItem("token");
    axios.defaults.headers.common["token"] = AUTH_TOKEN;
    const result = await axios.get(`/user/profile`);
    if (result) {
      return result;
    }
  } catch (err) {
    return { data: err?.response?.data };
  }
};

export const deleteAccountApi = async () => {
  try {
    const AUTH_TOKEN = localStorage.getItem("token");
    axios.defaults.headers.common["token"] = AUTH_TOKEN;
    const result = await axios.delete(`/user/profile`);
    if (result) {
      return result;
    }
  } catch (err) {
    return { data: err?.response?.data };
  }
};

export const resetPassApi = async (data) => {
  try {
    const result = await axios.patch(`/user/reset-password`, data);
    if (result) {
      return result;
    }
  } catch (err) {
    return { data: err?.response?.data };
  }
};

export const verifyOtpApi = async (data) => {
  try {
    const result = await axios.post(`/user/verify-otp`, data);
    if (result) {
      return result;
    }
  } catch (err) {
    return { data: err?.response?.data };
  }
};

export const ResendOTPApi = async (data) => {
  try {
    const result = await axios.post(`/user/resend-otp`, data);
    if (result) {
      return result;
    }
  } catch (err) {
    return { data: err?.response?.data };
  }
};

export const getPropertyApi = async (data) => {
  try {
    const AUTH_TOKEN = localStorage.getItem("token");
    axios.defaults.headers.common["token"] = AUTH_TOKEN;
    const result = await axios.get(
      `/property?pagination=${data?.page ? data?.page : 1}&limit=10${
        data && data?.search
          ? `&search=${data?.search ? data?.search : ""}`
          : ""
      }`
    );
    if (result) {
      return result;
    }
  } catch (err) {
    return { data: err?.response?.data };
  }
};

export const getPropertyDetailsApi = async (id) => {
  try {
    const AUTH_TOKEN = localStorage.getItem("token");
    axios.defaults.headers.common["token"] = AUTH_TOKEN;
    const result = await axios.get(`/property/${id}`);
    if (result) {
      return result;
    }
  } catch (err) {
    return { data: err?.response?.data };
  }
};

export const getGraphDetailsApi = async (id) => {
  try {
    const AUTH_TOKEN = localStorage.getItem("token");
    axios.defaults.headers.common["token"] = AUTH_TOKEN;
    const result = await axios.get(`/property/summary?id=${id}&page=1&limit=5`);
    if (result) {
      return result;
    }
  } catch (err) {
    return { data: err?.response?.data };
  }
};

export const cantactUsApi = async (data) => {
  try {
    const AUTH_TOKEN = localStorage.getItem("token");
    axios.defaults.headers.common["token"] = AUTH_TOKEN;
    const result = await axios.post(`/user/contact-us`, data);
    if (result) {
      return result;
    }
  } catch (err) {
    return { data: err?.response?.data };
  }
};

export const buyBricsApi = async (data) => {
  try {
    const AUTH_TOKEN = localStorage.getItem("token");
    axios.defaults.headers.common["token"] = AUTH_TOKEN;
    const result = await axios.post(
      `/property/buy
`,
      data
    );
    if (result) {
      return result;
    }
  } catch (err) {
    return { data: err?.response?.data };
  }
};

export const getBuyBricsApi = async (data) => {
  try {
    const AUTH_TOKEN = localStorage.getItem("token");
    axios.defaults.headers.common["token"] = AUTH_TOKEN;
    const result = await axios.get(
      `/property/buy?pagination=${data?.page ? data?.page : 1}&limit=10
`
    );
    if (result) {
      return result;
    }
  } catch (err) {
    return { data: err?.response?.data };
  }
};

export const addNomineApi = async (data, imageUrl, imageUrlBack) => {
  try {
    const AUTH_TOKEN = localStorage.getItem("token");
    axios.defaults.headers.common["token"] = AUTH_TOKEN;
    const formData = new FormData();

    formData.append("name", data?.name);
    formData.append("relation", data?.relation);
    formData.append("percent", data?.percent);
    formData.append("aadharcardNo", data?.aadharcardNo);
    formData.append("aadharcard", imageUrl);
    formData.append("aadharcardBack", imageUrlBack);

    const result = await axios.post(`/user/nominee`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (result) {
      return result;
    }
  } catch (err) {
    return { data: err?.response?.data };
  }
};

export const addDocApi = async (data, imageUrl, adharUrlBack, panUrl) => {
  try {
    const AUTH_TOKEN = localStorage.getItem("token");
    axios.defaults.headers.common["token"] = AUTH_TOKEN;
    const formData = new FormData();

    formData.append("aadharcardNo", data?.aadharcardNo);
    formData.append("panCardNo", data?.panCardNo);
    formData.append("panCard", panUrl);
    formData.append("aadharcard", imageUrl);
    formData.append("aadharcardBack", adharUrlBack);

    const result = await axios.post(`/user/doc`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (result) {
      return result;
    }
  } catch (err) {
    return { data: err?.response?.data };
  }
};

export const updateDocApi = async (data, imageUrl, adharUrlBack, panUrl) => {
  try {
    const AUTH_TOKEN = localStorage.getItem("token");
    axios.defaults.headers.common["token"] = AUTH_TOKEN;

    const formData = new FormData();

    formData.append("aadharcardNo", data?.aadharcardNo);
    formData.append("panCardNo", data?.panCardNo);
    formData.append("panCard", panUrl);
    formData.append("aadharcard", imageUrl);
    formData.append("aadharcardBack", adharUrlBack);

    const result = await axios.patch(`/user/doc`, formData);
    if (result) {
      return result;
    }
  } catch (err) {
    return { data: err?.response?.data };
  }
};

export const editProfileApi = async (data) => {
  try {
    const AUTH_TOKEN = localStorage.getItem("token");
    axios.defaults.headers.common["token"] = AUTH_TOKEN;
    const result = await axios.patch(`/user/profile`, data);
    if (result) {
      return result;
    }
  } catch (err) {
    return { data: err?.response?.data };
  }
};

export const getUserNominee = async () => {
  try {
    const AUTH_TOKEN = localStorage.getItem("token");
    axios.defaults.headers.common["token"] = AUTH_TOKEN;
    const result = await axios.get(`/user/nominee`);
    if (result) {
      return result;
    }
  } catch (err) {
    return { data: err?.response?.data };
  }
};

export const deleteNomineeByID = async (nomineeId) => {
  try {
    const AUTH_TOKEN = localStorage.getItem("token");
    axios.defaults.headers.common["token"] = AUTH_TOKEN;
    const result = await axios.delete(`/user/nominee/${nomineeId}`);
    if (result) {
      return result;
    }
  } catch (err) {
    return { data: err?.response?.data };
  }
};