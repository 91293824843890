import { useEffect, useState } from "react";
import { getProfileApi, getPropertyDetailsApi } from "../../api";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "toastr";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AboutLocation from "../../components/Modals/aboutLocationModal";
import {
  faArrowLeft,
  faCar,
  faChild,
  faDumbbell,
  faShieldAlt,
  faSwimmer,
  faTree,
  faDice,
  faBatteryFull,
  faHouseUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DummyGraph from "../../components/Graph";
import BuyBricsModal from "../../components/Modals/buyBricsModal";
import ImagePreview from "../../components/imagePreview";

const AMENITY_ICONS = {
  "Children's Play Area": faChild,
  "Swimming Pool": faSwimmer,
  "Indoor Games": faDice,
  "Landscaping & Tree Planting": faTree,
  "24x7 Security": faShieldAlt,
  "Power Backup": faBatteryFull,
  Gymnasium: faDumbbell,
  "Club House": faHouseUser,
};

const settings = {
  dots: false,
  infinite: true,
  speed: 2000,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
  ],
};

const PropertyDetailsPage = () => {
  toast.options = { preventDuplicates: true };
  const navigate = useNavigate();
  const params = new URLSearchParams(useLocation().search).get("id");
  const [propertyDetails, setPropertyDetails] = useState({});
  const [userDetails, setUserDetails] = useState({});
  const [loader, setLoader] = useState(false);
  const [showReadMe, setShowReadMe] = useState(false);
  const [buyShow, setBuyShow] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState("");
  const [checked, setChecked] = useState(false);

  const handleCheckboxChange = () => {
    setChecked(!checked);
  };

  const openModal = (image) => {
    setCurrentImage(image);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentImage("");
  };

  const handleBuyClose = () => {
    setBuyShow(false);
  };

  useEffect(() => {
    getPropertyDetails();
    getProfile();
  }, []);

  const getPropertyDetails = async () => {
    try {
      if (params) {
        setLoader(true);
        const res = await getPropertyDetailsApi(params);
        if (res?.status == 200) {
          setLoader(false);
          setPropertyDetails(res?.data?.data);
        } else {
          setLoader(false);
        }
      }
    } catch (err) {
      setLoader(false);
    }
  };

  const getProfile = async () => {
    if (localStorage.getItem("token")) {
      try {
        const res = await getProfileApi();
        if (res?.status == 200) {
          setUserDetails(res?.data?.data);
        } else {
          setLoader(false);
        }
      } catch (err) {
        setLoader(false);
      }
    }
  };

  const handleReadMore = () => {
    setShowReadMe(true);
  };

  const onClose = () => {
    setShowReadMe(false);
  };

  const currancyFormat = (number = 0) => number.toLocaleString('en-IN', {
    style: 'currency',
    currency: 'INR',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
 
  const mapUrl = `https://www.google.com/maps?q=${propertyDetails?.lat},${propertyDetails.lng}&hl=es;z=10&output=embed`;

  return (
    <>
      {loader ? (
        <div className="d-flex justify-content-center text-primary mb-12 mt-12">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8">
          <div className="w-52">
            <p
              className=" font-bold text-primary text-xl mb-4 cursor-pointer "
              onClick={() => navigate(-1)}
            >
              <FontAwesomeIcon icon={faArrowLeft} /> Back
            </p>
          </div>
          <div className="flex flex-col lg:flex-row lg:justify-between">
            <div className="w-full lg:w-7/12 mb-8 lg:mb-0">
              {propertyDetails?.images &&
              propertyDetails?.images.length <= 1 ? (
                <div className="image-container">
                  <img
                    src={propertyDetails?.images[0]}
                    alt={`Image`}
                    className="image-property"
                    onClick={() => openModal(propertyDetails?.images[0])}
                  />
                </div>
              ) : (
                <Slider {...settings}>
                  {propertyDetails?.images?.map((img, index) => (
                    <div className="image-container" key={index}>
                      <img
                        src={img}
                        alt={`Image ${index + 1}`}
                        className="image-property"
                        onClick={() => openModal(img)}
                      />
                    </div>
                  ))}
                </Slider>
              )}

              <div className="">
                <DummyGraph id={propertyDetails?.id} />
              </div>
            </div>

            <div className="w-full lg:w-5/12 lg:ml-8">
              <h2 className="text-3xl text-center text-primary font-bold mb-6">
                {propertyDetails?.location}
              </h2>
              <div className="p-6 border border-gray-200 shadow-lg bg-white rounded-lg transition-transform transform ">
                <h2 className="text-lg font-semibold text-gray-800 mb-4 w-85" style={{width:'85%'}}>
                  Property Details
                </h2>
                {[
                  {
                    label: "Total Brics Cost",
                    value: propertyDetails?.totalBricsCost,
                  },
                  {
                    label: "Each Bric Cost",
                    value: propertyDetails?.eachBricCost,
                  },
                  { label: "Total Brics", value: propertyDetails?.totalBrics },
                  {
                    label: "Available Brics*",
                    value: propertyDetails?.availableBrics,
                  },
                  {
                    label: "Rent Yield Per Month",
                    value: propertyDetails?.rentPerMonth,
                  },
                ].map((item, index) => (
                  <div
                    key={index}
                    className="flex justify-between items-center border-b border-gray-200 py-2 last:border-b-0 hover:bg-gray-50 transition duration-200"
                  >
                    <span className="font-medium text-gray-700">
                      {item.label}
                    </span>
                    <span className="font-semibold text-gray-900">
                      {currancyFormat(item.value)}
                    </span>
                  </div>
                ))}
                 
              </div>

              <div className="mt-12 w-full flex flex-col items-center lg:w-[420px]">
                <div className="mb-4 w-full">
                  <iframe
                    src={mapUrl}
                    height="350"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    className="rounded-lg w-full"
                  ></iframe>
                </div>
                {propertyDetails?.availableBrics == 0 ? (
                  ""
                ) : (
                  <button
                    onClick={() => {
                      if (localStorage.getItem("token")) {
                        // userDetails?.nominee?.id &&
                        if (
                          userDetails?.document?.id &&
                          userDetails?.docStatus != "reject"
                        ) {
                          if (!userDetails?.isEmailVerify) {
                            toast.error("Please verify your email first!");
                            navigate("/profile");
                          } else {
                            if (userDetails?.verify) {
                              setBuyShow(true);
                            } else {
                              toast.error(
                                "Please wait, your profile is currently under review!"
                              );
                            }
                          }
                        } else if (
                          userDetails?.document?.id &&
                          userDetails?.docStatus == "reject"
                        ) {
                          toast.error(
                            "Your documents are rejected you need to re-upload your docs!"
                          );
                          navigate("/profile");
                        } else {
                          toast.error(
                            "Please add your nominee details and documents first!"
                          );
                          navigate("/profile");
                        }
                      } else {
                        navigate("/login");
                      }
                    }}
                    className="border border-primary text-primary hover:text-white hover:bg-primary w-full rounded-full px-4 py-2 transition duration-300"
                  >
                    Buy Brics
                  </button>
                )}
              </div>
            </div>
          </div>

          <div className="mt-12  p-4">
            <h3 className="text-xl text-primary font-bold mb-2">
              About the Location:
            </h3>
            <p className="text-gray-600">
              {propertyDetails?.aboutLocation?.length > 150
                ? `${propertyDetails.aboutLocation.substring(0, 150)}...`
                : propertyDetails?.aboutLocation}
              {propertyDetails?.aboutLocation?.length > 150 && (
                <span
                  className="text-red-500 cursor-pointer ml-2"
                  onClick={handleReadMore}
                >
                  Read More
                </span>
              )}
            </p>

            <h3 className="text-xl text-primary font-bold mt-6 mb-2">
              About Property:
            </h3>
            <p className="text-gray-600">{propertyDetails?.aboutProperty}</p>

            <p className="text-gray-600 mt-6">
              <span className="text-xl text-primary font-bold ">
                Layout Area:{" "}
              </span>
              {propertyDetails?.area}
            </p>

            <div className="flex w-full flex-wrap mt-4">
              <p className="font-bold text-primary text-xl whitespace-nowrap">
                {" "}
                Amenities :
              </p>{" "}
              {propertyDetails?.amenities?.length > 0 ? (
                propertyDetails.amenities.map((val, index) => {
                  const Icon = AMENITY_ICONS[val];
                  return (
                    <span key={index}>
                      <p className="mt-1 text-gray-600 ml-4 whitespace-nowrap">
                        {Icon && <FontAwesomeIcon icon={Icon} />} {val}
                      </p>
                      {index < propertyDetails.amenities.length - 1 && ", "}
                    </span>
                  );
                })
              ) : (
                <p className="mt-1 text-gray-600 ml-4 whitespace-nowrap">N/A</p>
              )}
            </div>
            <p className="text-gray-600">
              <span className="text-xl text-primary font-bold">
                Documents:{" "}
              </span>
              <div className="doc-preview-container">
              {propertyDetails?.documentsLink &&
              propertyDetails.documentsLink.length > 0 ? (
                <div className="list-disc list-inside">
                  {propertyDetails.documentsLink.map((link, index) => (
                    <li key={index} className="doc-preview">
                      <a
                        href={link}
                        download
                        className="text-blue-500 underline doc-input-fix"
                      >
                   {link.split("/").pop()}
                      </a>
                    </li>
                  ))}
                </div>
              ) : (
                "No documents available"
              )}
              </div>
            </p>
          </div>
        </div>
      )}
      <BuyBricsModal
        getPropertyDetails={getPropertyDetails}
        show={buyShow}
        onClose={handleBuyClose}
        propertyDetails={propertyDetails}
        checked={checked}
        handleCheckboxChange={handleCheckboxChange}
      ></BuyBricsModal>

      <AboutLocation show={showReadMe} onClose={onClose}>
        <h3 className="text-xl text-primary font-bold mb-2">
          About the Location:
        </h3>
        <p className="text-gray-600">{propertyDetails?.aboutLocation}</p>
      </AboutLocation>
      <ImagePreview
        image={currentImage}
        isModalOpen={isModalOpen}
        closeModal={closeModal}
      />
    </>
  );
};

export default PropertyDetailsPage;
